<template>
  <div class="text">
    <p>
      L'investissement locatif est l'une des solutions les plus vantée pour vous mettre en sécurité financièrement.
      C'est un fait, cela permet de mettre de l'argent dans un actif qui ne dévalue pas. Au contraire, dans beaucoup d'endroit, les prix augmentent.
    </p>
    <p>
      De plus, certains se vantent de vendre un "rendement locatif" élevé.
      Comme si le bénéfice d'être propriétaire n'en était pas déjà un.
    </p>
    <p>
      Comme si <b>le fait de faire rembourser son emprunt par un plus pauvre que soit était une chose raisonnable.</b>
    </p>
  </div>
</template>

<script>
export default {
  name: "TextItem",
}
</script>

<style scoped>
.text {
  color: white;
  padding: 1rem 3rem;
  font-size: 1.25rem;
}
</style>